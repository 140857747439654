<template>
  <header>
    <div class="header-content">
      <h1>Story App</h1>
      <div class="hamburger-menu" v-if="isMobile">
        <button @click="toggleMenu">&#9776;</button>
      </div>
    </div>
    <div class="menu-content" v-if="isMobile && menuOpen">
      <button v-for="story in stories" :key="story.id" @click="selectStory(story.id)">
        {{ story.title }}
      </button>
    </div>
    <div class="button-container" v-if="!isMobile">
      <button v-for="story in stories" :key="story.id" @click="selectStory(story.id)">
        {{ story.title }}
      </button>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderComponent',
  props: {
    stories: Array
  },
  data() {
    return {
      isMobile: false,
      menuOpen: false
    };
  },
  methods: {
    selectStory(storyId) {
      this.$emit('selectStory', storyId);
      if (this.isMobile) {
        this.menuOpen = false;
      }
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 600;
    }
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>

<style>
header {
  background-color: #42b983;
  color: white;
  padding: 10px;
  text-align: center;
  position: relative;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button-container {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
}

button {
  background-color: white;
  color: #42b983;
  border: 1px solid #42b983;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

button:hover {
  background-color: #269561;
  color: white;
}

.hamburger-menu {
  display: none;
}

.hamburger-menu button {
  background-color: white;
  color: #42b983;
  border: none;
  font-size: 20px; /* Kleinere Größe für das Hamburger-Icon */
  cursor: pointer;
  padding: 5px 10px;
}

.menu-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: #42b983;
  padding: 10px;
  margin-top: 10px; /* Abstand zum Titel */
}

.menu-content button {
  background-color: white;
  color: #42b983;
  border: 1px solid #42b983;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.menu-content button:hover {
  background-color: #000000;
  color: white;
}

@media (max-width: 600px) {
  .button-container {
    display: none;
  }

  .hamburger-menu {
    display: block;
  }

  .header-content {
    justify-content: space-between;
    width: 100%;
  }

  header h1 {
    font-size: 18px; /* Kleinere Größe für den Titel auf mobilen Geräten */
  }
}
</style>

<template>
  <div class="story-container">
    <div class="story-content">
      <h2>{{ story.title }}</h2>
      <p v-html="formattedContent"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StoryDetail',
  props: {
    story: Object
  },
  data() {
    return {
      isMobile: false
    };
  },
  computed: {
    formattedContent() {
      const publicPath = process.env.BASE_URL; // Vue CLI setzt diesen Wert basierend auf `publicPath` in vue.config.js
      let content = this.story.content.replace(/\n/g, '<br>');

      // Ersetze die Platzhalter im Format IMAGE_KI_X durch Bilder
      content = content.replace(/IMAGE_KI_\d+/g, (match) => {
        const webpPath = `${publicPath}images/story_${this.story.id}/${match}.webp`;
        const pngPath = `${publicPath}images/story_${this.story.id}/${match}.png`;

        if (this.imageExists(webpPath)) {
          return `<img src="${webpPath}" alt="${match}" class="story-image">`;
        } else if (this.imageExists(pngPath)) {
          return `<img src="${pngPath}" alt="${match}" class="story-image">`;
        } else {
          // Optional: Placeholder image or error handling
          return `<img src="${pngPath}" alt="${match}" class="story-image">`;
        }
      });

      return content;
    },
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth <= 600;
    },
    imageExists(imagePath) {
      // Diese Methode überprüft, ob das Bild existiert
      const xhr = new XMLHttpRequest();
      xhr.open('HEAD', imagePath, false);
      xhr.send();
      return xhr.status !== 404;
    }
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>

<style>
.story-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.story-content {
  max-width: 600px;
  font-family: 'Georgia', serif;
  font-size: 18px;
  line-height: 1.6;
  text-align: justify;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

p {
  white-space: pre-wrap;
}

.story-image {
  display: block;
  margin: 20px auto;
  max-width: 100%;
  height: auto;
}
</style>

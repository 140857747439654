<template>
  <footer>
    <p>&copy; 2024 Story App</p>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent'
}
</script>

<style>
footer {
  background-color: #42b983;
  color: white;
  padding: 20px;
  text-align: center;
  position: relative;
  width: 100%;
}

@media (max-width: 600px) {
  footer {
    padding: 10px;
    font-size: 14px;
  }
}
</style>

<template>
  <div id="app">
    <HeaderComponent :stories="stories" @selectStory="selectStory" />
    <div class="content-container">
      <StoryDetail v-if="selectedStory" :story="selectedStory" />
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue';
import StoryDetail from './components/StoryDetail.vue';
import FooterComponent from './components/FooterComponent.vue';

export default {
  name: 'App',
  components: {
    HeaderComponent,
    StoryDetail,
    FooterComponent
  },
  data() {
    return {
      stories: [],
      selectedStory: null
    };
  },
  methods: {
    selectStory(storyId) {
      this.selectedStory = this.stories.find(story => story.id === storyId);
    },
    async loadStories() {
      try {
        const response = await fetch('stories.json');
        this.stories = await response.json();
        // Setze die erste Geschichte als ausgewählte Geschichte
        this.selectedStory = this.stories.find(story => story.id === 1);
      } catch (error) {
        console.error('Error loading stories:', error);
      }
    }
  },
  mounted() {
    this.loadStories();
  }
};
</script>

<style>
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-container {

}
</style>
